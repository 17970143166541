/* HOW TO ADD FONT TO PROJECT */

/* Add your font with a .ttf format to ./assets/styles/fonts/ */
/* Add a font-face to index.css */

/* EXAMPLE : */
/* @font-face { */
/* font-family: [font name] */
/* src: local([font name]), url([font path]) format('truetype') */
/* } */

@font-face {
  font-family: 'PoppinsRegular';
  src: local('PoppinsRegular'), url('./assets/styles/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratRegular';
  src: local('MontserratRegular'), url('./assets/styles/fonts/Montserrat/static/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratBold';
  src: local('MontserratBold'), url('./assets/styles/fonts/Montserrat/static/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratMedium';
  src: local('MontserratMedium'), url('./assets/styles/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratMediumItalic';
  src: local('MontserratMediumItalic'), url('./assets/styles/fonts/Montserrat/static/Montserrat-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'MontserratSemiBold';
  src: local('MontserratSemiBold'), url('./assets/styles/fonts/Montserrat/static/Montserrat-SemiBold.ttf') format('truetype');
}
